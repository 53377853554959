<template>
  <div class="container txt-logo features-list text-2xl">
    <div class="py-5">
      <h2
        class="text-center font-bold nunitoBold text-4xl uppercase"
      >{{ $t('Chính sách giá HODO PRO Platform') }}</h2>
    </div>
    <div class="mb-32">
      <div class="table-responsive relative">
        <table class="relative table table-hover table-bordered text-center align-middle">
          <thead>
            <tr class="border-0 bg-transparent">
              <th class="w-1/4 border-0 p-0" style="background: transparent"></th>
              <th
                class="w-1/6 border-0 p-0"
                style="background: transparent"
                v-for="(pack, index) in hodoServiceMonthPrice"
                :key="index"
              >
                <h3 class="bg-blue-50 border rounded-t-2xl px-2 py-3 mb-0 mx-0.5">
                  <span
                    class="block nunitoBold text-pri-900 border-bottom pb-1 mb-1"
                  >{{ $t(pack.name) }}</span>
                  <span class="block text-lg lg:text-2xl whitespace-pre">
                    <span
                      class="uppercase"
                    >{{ (pack.price !== '-') && (pack.price !== 'Liên hệ') && (pack.price !== null) ? pack.price : $t('Liên hệ') }}</span>
                    <span
                      v-if="(pack.price !== '-') && (pack.price !== 'Liên hệ') && (pack.price !== null)"
                    >/ {{ $t('Tháng') }}</span>
                  </span>
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in hodoServicesBenefit">
              <template v-if="item.sub_benefit">
                <template v-for="(subItem, idx) in item.sub_benefit">
                  <tr
                    class="cursor-pointer relative z-10"
                    :class="{
                      'bg-blue-50': activeIndex === `${index}-${idx}`
                    }"
                    :key="`${index}-${idx}`"
                    @click.stop="showDetails(subItem, `${index}-${idx}`)"
                  >
                    <td class="text-left">
                      <span
                        v-if="subItem.description.length"
                        class="inline-block"
                        :style="{
                          transform: activeIndex === `${index}-${idx}` ? `rotate(0)` : `rotate(-90deg)`
                        }"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
                        </svg>
                      </span>
                      <span
                        :class="{
                          'pl-6': !subItem.description.length
                        }"
                      >{{ subItem.name }}</span>
                    </td>
                    <template v-if="activeIndex === '0-0'">
                      <td v-if="idx === 0" :rowspan="1" v-html="formatterData(item.standard)"></td>
                      <td v-if="idx === 0" :rowspan="1" v-html="formatterData(item.plus)"></td>
                      <td v-if="idx === 0" :rowspan="1" v-html="formatterData(item.pro)"></td>
                    </template>
                    <template v-else-if="activeIndex === '0-1'">
                      <td v-if="idx === 0" :rowspan="2" v-html="formatterData(item.standard)"></td>
                      <td v-if="idx === 0" :rowspan="2" v-html="formatterData(item.plus)"></td>
                      <td v-if="idx === 0" :rowspan="2" v-html="formatterData(item.pro)"></td>
                    </template>
                    <template v-else>
                      <td
                        v-if="idx === 0"
                        :rowspan="item.sub_benefit.length"
                        v-html="formatterData(item.standard)"
                      ></td>
                      <td
                        v-if="idx === 0"
                        :rowspan="item.sub_benefit.length"
                        v-html="formatterData(item.plus)"
                      ></td>
                      <td
                        v-if="idx === 0"
                        :rowspan="item.sub_benefit.length"
                        v-html="formatterData(item.pro)"
                      ></td>
                    </template>
                  </tr>
                  <tr
                    class="bg-white relative z-20"
                    v-if="activeIndex === `${index}-${idx}` && hodoServiceDetail"
                    :key="`detail-${index}-${idx}`"
                  >
                    <td :colspan="hodoServiceMonthPrice.length + 1" class="text-left">
                      <ul class="list-disc list-inside mb-0">
                        <li v-for="(item, i) of hodoServiceDetail.description" :key="i">{{ item }}</li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </template>
              <tr
                class="cursor-pointer relative z-10"
                :class="{
                  'bg-blue-50': activeIndex === index
                }"
                v-else
                :key="index"
                @click.stop="showDetails(item, index)"
              >
                <td class="text-left">
                  <span
                    v-if="item.description.length"
                    class="inline-block"
                    :style="{
                      transform: activeIndex === index ? `rotate(0)` : `rotate(-90deg)`
                    }"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
                    </svg>
                  </span>
                  <span
                    :class="{
                      'pl-6': !item.description.length
                    }"
                  >{{ item.name }}</span>
                </td>
                <td v-html="formatterData(item.standard)"></td>
                <td v-html="formatterData(item.plus)"></td>
                <td v-html="formatterData(item.pro)"></td>
              </tr>
              <template>
                <tr
                  class="bg-white relative z-20"
                  v-if="activeIndex === index && hodoServiceDetail && hodoServiceDetail.description.length"
                  :key="`detail-${index}`"
                >
                  <td :colspan="hodoServiceMonthPrice.length + 1" class="text-left">
                    <ul class="list-disc list-inside mb-0">
                      <li
                        v-for="(item, index) of hodoServiceDetail.description"
                        :key="index"
                      >{{ item }}</li>
                    </ul>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td class="nunitoBold text-left">Chi phí/ Năm</td>
              <td v-for="(pack, index) in hodoServiceYearPrice" :key="index">
                <div class="mb-2 nunitoBold uppercase whitespace-nowrap">{{ pack.price }}</div>
                <!-- <div>
                  <a href="tel:0345900905" class="btn btn-pri whitespace-nowrap">{{ $t('Liên hệ') }}</a>
                </div>-->
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { HODO_PACKAGE } from '../../utils/constant'

export default {
  name: 'PricingTable',
  data () {
    return {
      appUtils,
      HODO_PACKAGE,
      hodoServicesBenefit: [],
      hodoServiceMonthPrice: [],
      hodoServiceYearPrice: [],
      hodoServiceDetail: null,
      activeIndex: null
    }
  },
  async created () {
    await this.getHodoServicesBenefit()
  },
  methods: {
    async getHodoServicesBenefit () {
      try {
        const res = await this.$rf
          .getRequest('GuestRequest')
          .getHodoSevicesBenefit()
        if (!res?.data) return
        this.hodoServicesBenefit = res.data?.benefits
        this.hodoServiceMonthPrice = res.data?.month_price
        this.hodoServiceYearPrice = res.data?.year_price
      } catch (error) {
        console.log(error)
      }
    },
    formatterData (value) {
      if (!value) return '-'
      if (value === 'ok') return '<i class="text-green-500">✓</i>'
      return value
    },
    onHide () {
      this.hodoServiceDetail = null
      this.activeIndex = null
    },
    showDetails (item, index) {
      if (this.activeIndex === index) {
        this.onHide()
        console.log(this.activeIndex)
        return
      }
      this.activeIndex = index
      console.log(this.activeIndex)
      this.hodoServiceDetail = { ...item }
    }
  }
}
</script>

<style lang="css" scoped>
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../../../public/font/Montserrat-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: Nunito;
  src: url("../../../public/font/Nunito-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Nunito;
  src: url("../../../public/font/NunitoSans-ExtraBold.ttf");
  font-weight: 700;
}

.txt-logo {
  color: #20409b;
}
.features-list /deep/ *,
.features-list /deep/ ul,
.features-list /deep/ ul li {
  font-family: Nunito !important;
  font-weight: 400;
}
.features-list /deep/ strong {
  font-family: Nunito !important;
  font-weight: 700;
}
.features-list /deep/ .el-collapse-item__header {
  height: auto !important;
  line-height: auto !important;
}
.features-list /deep/ .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.features-list /deep/ .el-collapse-item__arrow {
  display: none !important;
}
.features-list /deep/ table thead tr {
  background-color: #f1f2f2;
}
/* .features-list /deep/ table {
  border: 1px solid #dee2e6;
}
.features-list /deep/ table th,
.features-list /deep/ table td {
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  padding: 4px 8px;
} */
.features-list /deep/ table th {
  font-family: Nunito !important;
  font-weight: 700;
}
.nunitoRegular {
  font-family: Nunito !important;
  font-weight: 400;
}
.nunitoBold {
  font-family: Nunito !important;
  font-weight: 700;
}
.text-none {
  font-size: 0 !important;
}
</style>
